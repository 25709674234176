import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { BeamerProvider } from '../../beamer/BeamerProvider';
import GlobalErrorBoundary from '../../components/errorBoundaries/global/GlobalErrorBoundary';
import ObservableProvider from '../../components/ObservableProvider';
import { REACT_QUERY_DEVTOOLS_ENABLED } from '../../constants/config';
import { QuickSearchContextProvider } from '../../features/quick-search/QuickSearchContext';
import { queryClient } from '../../lib/queryClient';
import store from '../../redux/configureStore';
import Router from '../../routes';
import RuiThemeProvider from '../../RuiThemeProvider';
import UnleashProvider from '../../unleash/Unleash';
import ConnectedIntlProvider from '../ConnectedIntlProvider';
import { LoginAnimationContextProvider } from '../Login/LoginAnimationContext';
import { RossumXmas } from '../RossumXmas/RossumXmas';
import { handleIntlProviderError } from './handleIntlProviderError';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ObservableProvider>
      <Provider store={store}>
        <UnleashProvider>
          <RossumXmas>
            <RuiThemeProvider>
              <LoginAnimationContextProvider>
                <ConnectedIntlProvider onError={handleIntlProviderError}>
                  <GlobalErrorBoundary>
                    <BeamerProvider>
                      <QuickSearchContextProvider>
                        <Router />
                      </QuickSearchContextProvider>
                    </BeamerProvider>
                  </GlobalErrorBoundary>
                  {REACT_QUERY_DEVTOOLS_ENABLED && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                </ConnectedIntlProvider>
              </LoginAnimationContextProvider>
            </RuiThemeProvider>
          </RossumXmas>
        </UnleashProvider>
      </Provider>
    </ObservableProvider>
  </QueryClientProvider>
);

export default App;
