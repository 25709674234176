import { keyframes, Stack } from '@rossum/ui/material';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useSelector } from 'react-redux';
import { sum } from 'remeda';
import { currentDatapointSelector } from '../../redux/modules/datapoints/selector';
import hohoho from './audio/hohoho.mp3';
import santaFly from './santa-fly.png';

const fly = keyframes`
            0% {
                display: block;
                bottom: 0;
                left: -300px;
            }
            100% {
                bottom: 500px;
                left: 100% ;
            }`;

export const FlyingSanta = React.memo(() => {
  const currentDatapoint = useSelector(currentDatapointSelector);

  const positionSum =
    currentDatapoint && 'content' in currentDatapoint
      ? sum(currentDatapoint.content?.position ?? [])
      : null;
  const datapointId = currentDatapoint?.id ?? 0;

  const datapointRef = useRef({ id: datapointId, positionSum });

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const hasChangedPosition =
      datapointId !== 0 &&
      datapointRef.current.id === datapointId &&
      datapointRef.current.positionSum !== positionSum;

    let timeout: NodeJS.Timeout;

    if (hasChangedPosition) {
      setVisible(true);
      timeout = setTimeout(() => {
        setVisible(false);
      }, 4000);
    }

    datapointRef.current = { id: datapointId, positionSum };

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [datapointId, positionSum]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Stack
        sx={{
          width: 300,
          height: 150,
          position: 'absolute',
          zIndex: '999999',
          transform: 'rotate(-10deg)',
          bottom: 0,
          left: -300,
          animation: `${fly} ease-in-out 3500ms 1`,
          backgroundImage: `url(${santaFly})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          pointerEvents: 'none',
        }}
        spacing={2}
      />
      <ReactAudioPlayer autoPlay volume={1} src={hohoho} />
    </>
  );
});
