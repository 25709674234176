import { Box, Fade, Slide, Stack, Typography } from '@rossum/ui/material';
import cursor from './cursor.png';
import { FlyingSanta } from './FlyingSanta';
import merryChristmas from './mcc.gif?url';
import santa from './shoveling-santa.gif?url';
import snowLooong from './snowLooong.svg?url';
import { Snowman } from './Snowman';
import tree from './tree.gif?url';

type FallenSnowProps = {
  height: number;
  onClick: () => void;
};

export const FallenSnow = ({ height, onClick }: FallenSnowProps) => {
  const showWish = height === window.innerHeight;

  const showTree = window.location.pathname.includes('/document/');
  return (
    <Box
      onClick={onClick}
      style={{ height, zIndex: 100000 }}
      sx={{
        position: 'fixed',
        display: 'flex',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${snowLooong})`,
        backgroundSize: 'cover',
        zIndex: 10,
        cursor: `url(${cursor}), pointer`,
      }}
    >
      <FlyingSanta />
      <Snowman height={height} />
      {showWish && (
        <Fade in={showWish} unmountOnExit timeout={2000}>
          <Stack
            spacing={1}
            sx={{ alignSelf: 'center', margin: '0 auto' }}
            alignItems="center"
          >
            <img
              src={merryChristmas}
              alt="Merry Christmas from the FE team!"
              style={{ userSelect: 'none' }}
            />
            <Typography variant="h1" sx={{ color: '#C41E3A', fontWeight: 900 }}>
              from the FE team!
            </Typography>
          </Stack>
        </Fade>
      )}
      {height > 400 ? (
        <Slide direction="right" in={height > 400} timeout={2000}>
          <img
            src={santa}
            alt="Santa shoveling snow"
            style={{
              position: 'absolute',
              bottom: 0,
              left: 50,
              height: 150,
              zIndex: 100000,
              userSelect: 'none',
            }}
          />
        </Slide>
      ) : null}
      {showTree ? (
        <div
          style={{
            position: 'absolute',
            zIndex: 100000000,
            right: '50%',
            transform: 'translateX(50%)',
            bottom: 0,
          }}
        >
          <img
            src={tree}
            alt="Merry christmas!"
            style={{ height: 200, userSelect: 'none' }}
          />
        </div>
      ) : null}
    </Box>
  );
};
