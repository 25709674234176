import { keyframes, Slide, Stack } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as SnowmanImg } from './snowman.svg';

type SnowmanMood = 'happy' | 'worrying';
type SnowmanVisibility = 'visible' | 'hidden';

export const Snowman = ({ height }: { height: number }) => {
  const [snowmanMood, setSnowmanMood] = useState<SnowmanMood>('happy');
  const [snowmanVisibility, setSnowmanVisibility] =
    useState<SnowmanVisibility>('hidden');

  useEffect(() => {
    if (height < 150) {
      return setSnowmanVisibility('hidden');
    }

    if (height > 300) {
      setSnowmanMood('happy');
      return setSnowmanVisibility('visible');
    }

    if (height < 250) {
      return setSnowmanMood('worrying');
    }

    return undefined;
  }, [height]);

  if (snowmanVisibility === 'hidden') {
    return null;
  }

  const rotation = keyframes`
  0%, 42%, 57%, 100% {
      transform: rotate(0deg);
  }
  47% {
      transform: rotate(5deg);
  }
  52% {
      transform: rotate(-10deg);
  }`;

  return (
    <Slide
      direction="up"
      in={snowmanVisibility === 'visible'}
      unmountOnExit
      timeout={2000}
    >
      <Stack
        className={snowmanMood}
        onClick={e => {
          e.stopPropagation();
        }}
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          '&.happy .glove': {
            animation: `${rotation} 4000ms ease-in-out infinite`,
            transformOrigin: '40px 40px',
          },
          '&.worrying .eyes-a': {
            transform: 'scale(1.2)',
          },
          '&.worrying .eyes-b': {
            transform: 'scale(1.5)',
          },
          '&.worrying .mouth-1': {
            transform: 'scale(-1, -1) translate(55px, -10px) rotate(5deg)',
          },
          '&.worrying .mouth-2': {
            transform: 'scale(-1, -1) translate(-88px, 40px)',
          },
          '&.worrying .mouth-3': {
            transform: 'scale(-1, -1) translate(75px, 23px)',
          },
        }}
      >
        <SnowmanImg
          style={{
            zIndex: 9998,
            bottom: 0,
            right: 0,
            height: 300,
            width: 300,
          }}
          onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            e.stopPropagation();
          }}
        />
      </Stack>
    </Slide>
  );
};
