import { Slide } from '@rossum/ui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Snowfall from 'react-snowfall';
import { AudioPlayer } from './AudioPlayer';
import { FallenSnow } from './FallenSnow';
import { Rickroll } from './Rickroll';

type RossumXmasContextType = {
  isXmas: boolean;
  showPartyButton: boolean;
  getMerry: () => void;
  showMusicButton: boolean;
  showRickroll: boolean;
  rickroll: () => void;
  unrickroll: () => void;
  rickDirection: 'up' | 'down' | 'left' | 'right';
};

const randomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const directionMap: Record<number, 'up' | 'down' | 'left' | 'right'> = {
  0: 'up',
  1: 'down',
  2: 'left',
  3: 'right',
};

const RossumXmasContext = React.createContext<RossumXmasContextType | null>(
  null
);

export const RossumXmas = ({ children }: { children: React.ReactNode }) => {
  const [isXmas, setIsXmas] = useState(false);

  const [showPartyButton, setShowPartyButton] = useState(true);

  const [showMusicButton, setShowMusicButton] = useState(false);

  const [showRickroll, setShowRickroll] = useState(false);

  const [rickDirection, setRickDirection] = useState<
    'up' | 'down' | 'left' | 'right'
  >('up');

  const [snowHeight, setSnowHeight] = useState(0);

  useEffect(() => {
    if (isXmas) {
      setInterval(
        () => setSnowHeight(height => Math.min(height + 1, window.innerHeight)),
        200
      );
    }
  }, [isXmas]);

  const getMerry = useCallback(() => {
    setTimeout(() => setIsXmas(true), 600);
    setTimeout(() => setShowMusicButton(true), 10000);
    setShowPartyButton(false);
  }, []);

  const rickroll = useCallback(() => {
    const rnd = randomIntFromInterval(0, 3);

    const direction = directionMap[rnd] ?? 'up';
    setRickDirection(direction);
    setShowRickroll(true);
  }, []);

  const unrickroll = useCallback(() => {
    setShowRickroll(false);
  }, []);

  const ctxValue = useMemo(
    () => ({
      isXmas,
      showPartyButton,
      getMerry,
      showMusicButton,
      showRickroll,
      rickroll,
      unrickroll,
      rickDirection,
    }),
    [
      getMerry,
      isXmas,
      rickDirection,
      rickroll,
      showMusicButton,
      showPartyButton,
      showRickroll,
      unrickroll,
    ]
  );

  return (
    <RossumXmasContext.Provider value={ctxValue}>
      <Snowfall
        snowflakeCount={isXmas ? 700 : 0}
        changeFrequency={60}
        speed={[1, 5]}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: 100000,
        }}
      />
      {snowHeight > 0 && (
        <FallenSnow
          height={snowHeight}
          onClick={() => setSnowHeight(old => Math.max(0, old - 100))}
        />
      )}
      <Slide in={showMusicButton} direction="left" unmountOnExit>
        <AudioPlayer />
      </Slide>
      <Rickroll />
      {children}
    </RossumXmasContext.Provider>
  );
};

export const useXmasContext = () => {
  const ctx = React.useContext(RossumXmasContext);

  if (!ctx) {
    throw new Error('XMas context can only be used during XMas!');
  }

  return ctx;
};
