import { Box, Slide } from '@rossum/ui/material';
import astley from './astley.gif?url';
import { useXmasContext } from './RossumXmas';

export const Rickroll = () => {
  const { showRickroll, isXmas, rickDirection } = useXmasContext();

  const rickrollPosition = {
    ...(rickDirection === 'down'
      ? { top: 0 }
      : rickDirection === 'up'
        ? { bottom: 0 }
        : rickDirection === 'left'
          ? { right: 0 }
          : { left: 0 }),
  };

  return (
    <Slide in={isXmas && showRickroll} direction={rickDirection}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 120000,
          pointerEvents: 'none',
          ...rickrollPosition,
        }}
      >
        <img
          src={astley}
          alt="Rickroll"
          style={{
            margin: -25,
            transform: `rotate(${rickDirection === 'down' ? 180 : rickDirection === 'left' ? -90 : rickDirection === 'right' ? 90 : 0}deg)`,
            transformOrigin: 'center',
            userSelect: 'none',
          }}
        />
      </Box>
    </Slide>
  );
};
