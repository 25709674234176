import { ScienceRounded, WarningAmber } from '@rossum/ui/icons';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  Grow,
  keyframes,
  Slide,
  Stack,
  Tab as MUITab,
  Tabs,
  Tooltip,
} from '@rossum/ui/material';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'redux-first-history';
import { DEV_FEATURES_ENABLED } from '../../constants/config';
import CallToActionButton from '../../containers/CallToAction';
import { useLeavingModalDispatch } from '../../containers/Queue/containers/QueueSchema/lib/leavingModalHooks';
import lights from '../../containers/RossumXmas/lights2.png';
import { useXmasContext } from '../../containers/RossumXmas/RossumXmas';
import { useBillingEnabled } from '../../features/billing';
import { PricingBanner } from '../../features/pricing/components/PricingBanner';
import { usePricingBannerWarning } from '../../features/pricing/hooks/usePricingBannerWarning';
import { approvalWorkflowsFeatureSelector } from '../../features/pricing/selectors';
import { useQueues } from '../../features/queues/hooks/useQueues';
import { useQuickSearchContext } from '../../features/quick-search/QuickSearchContext';
import {
  isTrialSelector,
  safeOrganizationSelector,
} from '../../redux/modules/organization/selectors';
import {
  currentTabSelector,
  hideAppBarSelector,
} from '../../redux/modules/router/selectors';
import {
  canUserApproveSelector,
  userRoleNameSelector,
} from '../../redux/modules/user/selectors';
import { State } from '../../types/state';
import PoweredBy from '../UI/PoweredBy';
import UserPanel from '../UserPanel';
import Logo, { hasCustomLogoSelector } from './Logo';
import { isTabVisible, Tab, tabs, tabToLink } from './navigationStructure';
import PopperMenu from './PopperMenu';
import { SearchButton } from './SearchButton';

type NavigationTabsProps = {
  handleMenuOpen: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    currentTab: Tab
  ) => void;
  handleNavigation: (event: React.MouseEvent, pathname: string) => void;
};

const NavigationTabs = ({
  handleMenuOpen,
  handleNavigation,
}: NavigationTabsProps) => {
  const currentPathsTab = useSelector(currentTabSelector);
  const userRole = useSelector(userRoleNameSelector);

  const {
    data: queues,
    isSuccess: areQueuesLoaded,
    isFetched,
  } = useQueues({
    fields: ['id'],
    pageSize: 1,
  });

  const workflowsEnabled = useSelector(approvalWorkflowsFeatureSelector);
  const canUserApprove = useSelector(canUserApproveSelector);

  const organization = useSelector(safeOrganizationSelector);

  const intl = useIntl();

  const approverHasNoQueues =
    userRole === 'approver' && areQueuesLoaded && !queues.results.length;

  const visibleTabs = tabs.filter(tab => {
    if (tab.name === 'requests') return workflowsEnabled && canUserApprove;
    if (tab.name === 'documents') return !approverHasNoQueues;

    return true;
  });

  // the user might refresh the page in annotations path, and annotations might be temporarily excluded from the visible paths.
  // To make MUI happy here we make sure that the currentTab value being passed is always visible.
  const currentTab =
    visibleTabs.find(tab => tab.name === currentPathsTab?.name)?.name ?? false;

  const { getMerry, showPartyButton } = useXmasContext();

  const pulseEffect = keyframes`
            0%, 100% {
                outline-color: #DC143C; /* Christmas red */
                outline-width: 1px; /* Small outline */
                outline-offset: 1px;
            }
            50% {
                outline-color: #DC143C; /* Same color */
                outline-width: 2px; /* Larger outline */
                outline-offset: 4px;
            }`;

  return isFetched
    ? userRole && (
        <Stack direction="row" spacing={2} alignItems="center">
          <Grow in={showPartyButton} unmountOnExit timeout={600}>
            <Button
              variant="contained"
              size="small"
              onClick={getMerry}
              sx={{
                alignSelf: 'center',
                flexShrink: 0,
                backgroundColor: '#008000',
                border: '2px solid #006400',
                outline: '2px solid #006400',
                animation: `${pulseEffect} 2000ms ease-in-out infinite`,
                '&:hover': {
                  backgroundColor: '#006400',
                  transform: 'scale(1.05)',
                  transition: 'transform 0.3s ease',
                },
              }}
            >
              Merry X-mas!
            </Button>
          </Grow>
          {organization?.sandbox ? (
            <Tooltip
              title={intl.formatMessage({
                id: 'features.pricing.sandbox.tooltip',
              })}
            >
              <Chip
                label={intl.formatMessage({
                  id: 'features.pricing.sandbox.iconText',
                })}
                icon={<ScienceRounded />}
                color="primary"
                sx={{ px: 1 }}
                size="small"
              />
            </Tooltip>
          ) : null}
          {DEV_FEATURES_ENABLED ? (
            <Tooltip
              title={intl.formatMessage({
                id: 'features.pricing.developerMode.tooltip',
              })}
            >
              <Chip
                label="Developer mode"
                icon={<WarningAmber />}
                color="warning"
                sx={{ px: 1 }}
                size="small"
              />
            </Tooltip>
          ) : null}
          <Tabs value={currentTab} aria-label="nav-bar-tabs">
            {visibleTabs.map(tab => {
              // TODO @unified-dashboard: temporarily disable external url mutations when o /documents route https://rossumai.atlassian.net/browse/AC-4257
              const isDisabled =
                currentTab === 'documents' && tab.name === 'documents';

              return (
                isTabVisible(tab, userRole) && (
                  <MUITab
                    data-cy={`${tab.name}-navtab`}
                    key={tab.name}
                    label={intl.formatMessage({
                      id: `components.appBar.${tab.name}`,
                    })}
                    value={tab.name}
                    onClick={e => handleNavigation(e, tabToLink(tab))}
                    // its necessary to explicitly type this event to avoid the TS issues arising due to Tab expecting a div element, and us passing a Link element
                    onMouseEnter={(e: React.MouseEvent<HTMLAnchorElement>) =>
                      handleMenuOpen(e, tab)
                    }
                    component={Link}
                    to={tabToLink(tab)}
                    sx={{
                      fontSize: theme => theme.typography.pxToRem(13),
                      pointerEvents: isDisabled ? 'none' : 'auto',
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    }}
                    disableRipple
                  />
                )
              );
            })}
          </Tabs>
        </Stack>
      )
    : null;
};

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(
    null
  );
  const [hoveredTab, setHoveredTab] = useState<Tab | null>(null);
  const leaveSafely = useLeavingModalDispatch();
  const menuOpen = Boolean(anchorEl);

  const { isXmas } = useXmasContext();

  const { setOpen } = useQuickSearchContext();

  const openSearch = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    currentTab: Tab
  ) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setHoveredTab(currentTab);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHoveredTab(null);
  };

  const handleNavigation = (e: React.MouseEvent, pathname: string) => {
    if (e.metaKey || e.ctrlKey) return;

    e.preventDefault();
    handleMenuClose();
    leaveSafely(push(pathname));
  };

  const isTrial = useSelector((state: State) => isTrialSelector(state));

  const hasCustomLogo = useSelector(hasCustomLogoSelector);

  const hideAppBar = useSelector(hideAppBarSelector);

  const bannerWarning = usePricingBannerWarning();
  const { isEnabled: billingEnabled } = useBillingEnabled();

  if (hideAppBar) {
    return null;
  }

  return (
    <Box>
      <AppBar position="sticky" color="default" elevation={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          <Stack direction="row" alignItems="center">
            <Stack px={4}>
              <Logo />
            </Stack>
            <Stack onMouseLeave={handleMenuClose}>
              <NavigationTabs
                handleMenuOpen={handleMenuOpen}
                handleNavigation={handleNavigation}
              />
              <PopperMenu
                open={menuOpen}
                hoveredTab={hoveredTab}
                anchorEl={anchorEl}
                handleNavigation={handleNavigation}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <SearchButton onClick={openSearch} />
            {isTrial && <CallToActionButton ctaLocation="navigation-panel" />}
            {hasCustomLogo && <PoweredBy />}
            <UserPanel />
          </Stack>
        </Stack>
      </AppBar>
      <Slide in={isXmas} direction="down" unmountOnExit timeout={600}>
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            top: 50,
            zIndex: 100,
            height: 35,
            backgroundImage: `url(${lights})`,
            backgroundSize: 'contain',
            pointerEvents: 'none',
          }}
        />
      </Slide>
      <Divider />
      {bannerWarning ? (
        <PricingBanner
          warningText={bannerWarning}
          billingEnabled={billingEnabled}
        />
      ) : null}
    </Box>
  );
};

export default Navbar;
