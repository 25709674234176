import { IconMusic } from '@rossum/ui/icons/tabler';
import { Button, keyframes, Stack, SvgIcon } from '@rossum/ui/material';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import allIWant from './audio/all_i_want.mp3';
import doTheyKnow from './audio/do_they_know.mp3';
import snowman from './audio/frosty.mp3';
import jingleBells from './audio/jingle_bells.mp3';
import lastChristmas from './audio/last_christmas.mp3';
import neverGonna from './audio/never_gonna.mp3';
import { useXmasContext } from './RossumXmas';

export const AudioPlayer = forwardRef<HTMLDivElement, unknown>(
  (_props, ref) => {
    const { rickroll, unrickroll } = useXmasContext();

    const [currentSong, setCurrentSong] = useState(0);

    useEffect(() => {
      if (currentSong === 3) {
        rickroll();
      } else {
        unrickroll();
      }
    }, [currentSong, rickroll, unrickroll]);

    const songQueue = useMemo(() => {
      return [
        allIWant,
        jingleBells,
        lastChristmas,
        neverGonna,
        doTheyKnow,
        snowman,
      ];
    }, []);

    const pulseEffect = keyframes`
            0%, 100% {
                outline-color: #DC143C; /* Christmas red */
                outline-width: 1px; /* Small outline */
                outline-offset: 1px;
            }
            50% {
                outline-color: #DC143C; /* Same color */
                outline-width: 2px; /* Larger outline */
                outline-offset: 4px;
            }`;

    return (
      <Stack
        ref={ref}
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: `translateY(-50%)`,
          zIndex: 110000,
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            setCurrentSong(song => (song + 1) % songQueue.length);
          }}
          sx={{
            p: 0,
            minWidth: 0,
            width: 56,
            height: 56,
            backgroundColor: '#C41E3A',
            outline: '2px solid #006400',
            animation: `${pulseEffect} 2000ms ease-in-out infinite`,
            '&:hover': {
              backgroundColor: '#006400',
              transform: 'scale(1.05)',
              transition: 'transform 0.3s ease',
            },
          }}
        >
          <SvgIcon fontSize="large" sx={{ fontSize: 38 }}>
            <IconMusic />
          </SvgIcon>
        </Button>
        <ReactAudioPlayer
          src={songQueue[currentSong]}
          onEnded={() => setCurrentSong(song => (song + 1) % songQueue.length)}
          autoPlay
          volume={1}
        />
      </Stack>
    );
  }
);
